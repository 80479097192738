// *** React
import React, { useEffect, useState } from "react";
import "./footer.scss";
import { Row } from "react-bootstrap";
import { Col, Container } from "react-bootstrap/esm";
import darklogo from "../../assets/finalwlogo.svg";
import le from "../../assets/hero/le.svg";
import sy from "../../assets/hero/sy.svg";
import fa from "../../assets/hero/facebook.svg";
import li from "../../assets/hero/linkedin.svg";
// import tw from "../../assets/hero/twitter.svg";
import faw from "../../assets/facew.svg";
import liw from "../../assets/inw.svg";
import Ins from "../../assets/instagram.svg";
import what from "../../assets/whatsapp.svg";
import In from "../../assets/instagramw.svg";
import wha from "../../assets/whatsappw.svg";
// import tww from "../../assets/tww.svg";
// import buttonback from "../../assets/buttonback.svg";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const FooterLayout = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [id, setId] = useState("0");
  const hoverdImg = (e) => {
    setId(e.target.id);
  };
  let navigate = useNavigate();
  return (
    <div className="footer mt-5">
      <div className="form">
        <p>Sign up to get the latest digital Trends</p>
        <fieldset class="fieldInput">
          <input
            class="form-input"
            type="email"
            placeholder="Your Email Address"
          />
          <button type="submit" class="form-submit">
            Subscribe
          </button>
        </fieldset>
      </div>
      <div className="mt-5 mr-5 ml-5">
        <Container>
          <Row>
            <Col md={12} lg={3}>
              <div className="logoff">
                <img src={darklogo} width="100%" height="100%" alt="v" style={{cursor:"pointer"}} onClick={() => navigate("/")}  />
              </div>
              <p className="logotext">
                software company achieve clients' business goal
              </p>
            </Col>
            {/* <Col xs={6} sm={6} md={6} lg={2}>
              <div className="links">
                <ul>
                  <li>features</li>
                  <li>approach</li>
                  <li>clients</li>
                </ul>
              </div>
            </Col> */}
            <Col xs={6} sm={6} md={6} lg={3}>
              <div className="links">
                <ul>
                  <li>
                    <HashLink className="linkfoo" to="/#weOffer">
                      
                      Services
                     
                    </HashLink>
                   
                    <Link className="linkfoo" to="/projects" target="_top">
                      Portfolio
                    
                    </Link>
                    <Link className="linkfoo" to="/about" target="_top">
                      About
                    </Link>
                  </li>
                  <li>
                  <Link className="linkfoo1" to="/contact" target="_top">
                    Let’s Contact
                    </Link>
                  </li>
                  <li>
                  <div className="iconlinks">
                <ul>
                  <li>
                    <Link
                      to="https://www.facebook.com/Hnndes?mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img
                        id="1"
                        src={id === "1" ? faw : fa}
                        onMouseOver={hoverdImg}
                        onMouseLeave={() => setId("0")}
                        alt="facebook logo"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/hnndes/"
                      target="_blank"
                    >
                      {" "}
                      <img
                        id="2"
                        src={id === "2" ? liw : li}
                        onMouseOver={hoverdImg}
                        onMouseLeave={() => setId("0")}
                        alt="linkedin logo"
                      />
                    </Link>
                  </li>
                  <li>
                    {/* <img id="3" src={id==="3"?tww :tw} onMouseOver={hoverdImg} onMouseLeave={()=>setId("0")} alt="twitter logo" /> */}
                    <Link
                      to="https://www.instagram.com/hnndes.co?igsh=MWt4bHNndGlhaWU5OA=="
                      target="_blank"
                    >
                      {" "}
                      <img
                        id="3"
                        src={id === "3" ? In : Ins}
                        onMouseOver={hoverdImg}
                        onMouseLeave={() => setId("0")}
                        alt="Instagram logo"
                      />
                    </Link>
                  </li>
                  <li>
                    {/* <img id="3" src={id==="3"?tww :tw} onMouseOver={hoverdImg} onMouseLeave={()=>setId("0")} alt="twitter logo" /> */}
                    <Link
                      to="https://wa.me/message/PA27YYCDVXCRP1"
                      target="_blank"
                    >
                      {" "}
                      <img
                        id="4"
                        src={id === "4" ? wha : what}
                        onMouseOver={hoverdImg}
                        onMouseLeave={() => setId("0")}
                        alt="whatsapp logo"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
                  </li>
                  <li>
                    
                  </li>
                </ul>
              </div>
            </Col>

            {/* <Col xs={6} sm={6} md={6} lg={2}>
              <div style={{ color: "black" }}>
                <ul style={{ listStyleType: "none" }}>
                  <li></li>
                  <li></li>
                  <li></li>
                  {/* <li>features</li>
                  <li>approach</li>
                  <li>clients</li> 
                </ul>
              </div>
            </Col>
             */}
            {/* <Col xs={6} sm={6} md={6} lg={2}>
              <div className="linksc">
              <p className="connect"><Link className="linkfoo" to="/contact" target="_top">Let’s Contact</Link> </p>
              </div>
            </Col> */}
            <Col md={6} lg={3}>
              <div className="location">
                <div className="img">
                  <img src={le} alt="LE" width="40px" />
                </div>
                <p className="text">
                  Lebanon
                  <br /> Sin El fil
                  <br />
                  Gazal street
                  <br />
                  Chehade aboud Bldg
                  <br />
                  2nd floor <br />
                </p>
              </div>
            </Col>
            <Col md={6} lg={2}>
              <div className="location">
                <div className="img">
                  <img src={sy} alt="LE" width="50px" />
                </div>
                <p className="text">
                  Syria Homs, <br />
                  Al-Hamidiyah Street
                  <br />
                  City Center 2 Building
                  <br />
                  7th floor
                </p>
              </div>
            </Col>
          </Row>
          {/* <Row style={{ marginBottom: "40px" }}>
            <Col md={3}>
              <div className="iconlinks">
                <ul>
                  <li>
                    <Link
                      to="https://www.facebook.com/Hnndes?mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img
                        id="1"
                        src={id === "1" ? faw : fa}
                        onMouseOver={hoverdImg}
                        onMouseLeave={() => setId("0")}
                        alt="facebook logo"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/hnndes/"
                      target="_blank"
                    >
                      {" "}
                      <img
                        id="2"
                        src={id === "2" ? liw : li}
                        onMouseOver={hoverdImg}
                        onMouseLeave={() => setId("0")}
                        alt="linkedin logo"
                      />
                    </Link>
                  </li>
                  <li>
                    {/* <img id="3" src={id==="3"?tww :tw} onMouseOver={hoverdImg} onMouseLeave={()=>setId("0")} alt="twitter logo" /> */}
                    {/*  <Link
                      to="https://www.instagram.com/hnndes.co?igsh=MWt4bHNndGlhaWU5OA=="
                      target="_blank"
                    >
                      {" "}
                      <img
                        id="3"
                        src={id === "3" ? In : Ins}
                        onMouseOver={hoverdImg}
                        onMouseLeave={() => setId("0")}
                        alt="Instagram logo"
                      />
                    </Link>
                  </li>
                  <li>*/}
                    {/* <img id="3" src={id==="3"?tww :tw} onMouseOver={hoverdImg} onMouseLeave={()=>setId("0")} alt="twitter logo" /> */}
                    {/* <Link
                      to="https://wa.me/message/PA27YYCDVXCRP1"
                      target="_blank"
                    >
                      {" "}
                      <img
                        id="4"
                        src={id === "4" ? wha : what}
                        onMouseOver={hoverdImg}
                        onMouseLeave={() => setId("0")}
                        alt="whatsapp logo"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
        
          </Row> */}
      
        </Container>
      </div>
      <div className="copyright ">
        <Container>
          <div className="border-top ">
            <Row>
              <Col md={6}>
                <p className="d-flex flex-wrap">
                  <span className="d-inline-block">
                    © All Rights Reserved. Hnndes, LLC 2023
                  </span>
                  <span className="d-inline-block ml-3 cursor-pointer ">
                    privacy policy
                  </span>
                </p>
              </Col>
              <Col md={6}>
                <p className="d-flex justify-content-end flex-wrap1">
                  <span className="d-inline-block">info@hnndes.com</span>
                  {/* <span className="d-inline-block ml-3 cursor-pointer ">
                    Call us at (800) 105-9413
                  </span> */}
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default FooterLayout;
