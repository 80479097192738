import React, { useEffect, useRef, useState } from "react";
import NavHeader from "view/home/NavHeader";
import "./news.css";
import { useLocation, useParams } from "react-router-dom";
import { useHnndes } from "view/provider";
import ReactQuill from "react-quill";
import parse from "html-react-parser";
import NavContent from "view/NavContent";
function NewsDetails({ route, navigation }) {
  const location = useLocation();
  //   const {state} = useLocation();
  // const {id} = state;
  //const {params}=useParams();
  let { id } = useParams();
  const {
    state: { selected: article,alist:news,perPage,page },fetchArticles:fetchData,
    fetchArticleInfo,
  } = useHnndes();
  useEffect(() => {
    fetchData("1;2",page,perPage);
    console.log("projects",news)
   
  }, [fetchData]);
  const recentBlogs=news?.slice(Math.max(news.length - 3, 1))
  let heads = new Array();
  useEffect(() => {
    fetchArticleInfo(id, 1);

    // console.log(string_between_strings('<strong>','</strong>',testString),"........");

    // console.log( testString.split(/[<strong></strong>]/),">>>>")
  }, [fetchArticleInfo]);
  let arrr = new Array();
  const testString = article?.content + "";
  arrr = testString.split("<strong>");
  console.log(arrr, "........arr");
  let str2 = arrr[arrr.length - 1].split("</strong>")[0];
  console.log(str2, "str2");
  arrr.map((str, index) => {
    if (index != 0 && index != arrr.length - 1) {
      var str1 = str.split("</strong>");
      heads.push(str1[0]);
    }
  });
  console.log(heads, "Headssss");

  //console.log(article?.content.split('<strong>'),"article");

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }
  const [hover, setHover] = useState(false);

  const HoverData = "Click to copy url";
  const onHover = (e) => {
    e.preventDefault();
    setHover(true);
    console.log("hovered");
  };

  const onHoverOver = (e) => {
    e.preventDefault();
    setHover(false);
  };

  // heads.map((head,i)=>{

  //   console.log(head,"hwead")
  // }

  // )
  return (
    <div>
      <NavHeader />
      {/* <div className="mainDetails"> */}
      <NavContent
        fTitle="Blog"
        sTitle=" HNNDES"
        subtitle="ACCELERATE THROUGH SOFTWARE"
        details="Read HNNDES expert insights into the mobile, .NET, and web application development, learn the details of our most complex projects, and stay tuned for the industry's and our company's latest updates."
      />
      <div className="mainNews2">
        {/* العمود الأول */}
        <div className="mainNews1">
          <div className="mainNews1-firstDiv">
            {heads.map((head) => (
              <div className="news-borderd">
                <p>{head}</p>
                <br />
                <br />
              </div>
            ))}
            <div className="news-gBorderd">
              <p>{str2}</p>
              <br />
              <br />
            </div>
          </div>
          <div className="news-popular">
            <p>
            Popular articles:
            </p>
          </div>
         { recentBlogs.map((blog)=>
        <div className="rblogs-title"><p>{blog.title}</p></div>
          )
        }
        </div>
        {/* العمود الثاني */}
        <div className="mainNews">
          <p className="blog-title2">
            {article?.title}
            {/* <button onClick={copy}>{!copied ? "Copy link" : "Copied!"}</button>  */}
          </p>
          <div className="blog-img2">
            <img
              onMouseEnter={(e) => onHover(e)}
              onMouseLeave={(e) => onHoverOver(e)}
              src={article?.imageUrl}
              alt=""
              onClick={copy}
            />
            {hover && <p className={hover}>{HoverData}</p>}
          </div>

          <p className="blog-detail2">
            {parse(article?.content + "")}
            {/* <ReactQuill
            value={article?.content}
            readOnly={true}
            theme={"bubble"}
          /> */}
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewsDetails;
