// *** React
import React from "react";
import Approach from "./Approach/Approach";
import Values from "./Values/Values";
import WeDo from "./WeDo/WeDo";
import WeOffer from "./WeOffer/WeOffer";
import CaseStudies from "./CaseStudies/CaseStudies";
import Hero from "./Hero/Hero";
import NavHeader from "./NavHeader";
import Nav from "./Hero/Nav";
import OurClints from "./OurClints/OurClints"
import "./home.scss"
import MainTitle from "../MainTitle";
import { Link } from "react-router-dom";
import logo1 from "../../assets/stitchain.svg"
import logo2 from "../../assets/stitvest.svg"
import star from "../../assets/Star 3.svg"
import logo4 from "../../assets/formsEasyLogo.svg"
import logo5 from "../../assets/libancall3.svg"
import logo6 from "../../assets/albath.svg"
import logo7 from "../../assets/Pflege.svg"
import logo8 from "../../assets/niceMove.svg"
import logo9 from "../../assets/stitvestNew.svg"
// *** Styled Components

// *** Components

function HomeContainer() {
  const leftLogos = [
    { src: logo1,link:"https://www.stitchain.com/" },
   
   
    { src: logo4,link:"" },
    { src: logo5,link:"http://www.libancallplus.com/" },
    { src: logo2,link:"" },
    { src: logo6,link:"" },
    { src: logo7,link:"" },
    { src: logo8,link:"" },
    { src: logo9,link:"https://www.stitvest.com/" },
    { src: logo1,link:"https://www.stitchain.com/" },
   
   
    { src: logo4,link:"" },
    { src: logo5,link:"http://www.libancallplus.com/" },
    { src: logo2,link:"" },

  ];
  return <>
    <Hero />
   < div className="main">
  {/* <NavHeader/> */}

   <WeDo/>
   <div id="weOffer">
   <WeOffer name="weOffer"/>
   </div>
  
   <Values/>  
    <Approach/>
    
   
     <CaseStudies/> 
     {/* <OurClints/> */}
     <MainTitle
          fTitle="Our"
          sTitle="Partners"
          subtitle="From startups to fortune 500 companies,
          we create custom solutions that grow brands online"
         
          details="" 
        />
    </div>
    
  

<div class="slider-home">
            <div class="slide-track-home">
              <div class="slide-home">
                <img
                  src={logo1}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo2}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo4}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo6}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo7}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo8}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo5}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo9}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo1}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo2}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo4}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo6}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo7}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo8}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo5}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide-home">
                <img
                  src={logo9}
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              {/* <div class="slide-home">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div> */}
            </div>
          </div>

    </>
  
}

export default HomeContainer;
